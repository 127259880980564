@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  border: 0 solid #eaeaea;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html,
body {
  overscroll-behavior-x: none;
}
